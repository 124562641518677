import { useBreakpointValue } from "@chakra-ui/media-query";

const sizes = ["sm", "md", "lg", "xl", "2xl"]

export const breakpoints = sizes.reduce((acc, elt) => { acc[elt] = elt; return acc }, {})

/**
 * 
 * @param {*} breakpoint sm | md | lg | xl
 * @returns 
 */
export function useScreenLessThan(breakpoint) {
  const currentBreakpoint = useBreakpointValue(breakpoints)
  return sizes.indexOf(currentBreakpoint) < sizes.indexOf(breakpoint)
}

/**
 * 
 * @param {*} breakpoint sm | md | lg | xl
 * @returns 
 */
export function useScreenMoreThan(breakpoint) {
  const currentBreakpoint = useBreakpointValue(breakpoints)
  return sizes.indexOf(currentBreakpoint) > sizes.indexOf(breakpoint)
}
