import { Button } from "@chakra-ui/button";
import { Img } from "@chakra-ui/image";
import { Flex, Text } from "@chakra-ui/layout";
import { Spinner } from "@chakra-ui/spinner";
import { Tooltip } from "@chakra-ui/tooltip";
import { useState } from "react";
import { downloadFile } from "../util/download-util";

export default function PieceJointe({ fichier }) {
  const extension = fichier.name.split('.').pop();
  let logo;
  switch (extension) {
    case "xlsx":
    case "xls":
      logo = "/xls.png"
      break
    case "docx":
    case "doc":
      logo = "/doc.png"
      break
    case "pdf":
      logo = "/pdf.png"
      break
    case "pptx":
    case "ppt":
      logo = "/ppt.png"
      break
    case "rar":
    case "zip":
      logo = "/zip.png"
      break
    case "txt":
      logo = "/txt.png"
      break
    case "bmp":
    case "gif":
    case "ico":
    case "jpeg":
    case "jpg":
    case "png":
      logo = "/image.png"
      break
    default:
      logo = "/file.png"
  }

  const [telechargement, setTelechargement] = useState(false)

  function telecharger() {
    setTelechargement(true)
    downloadFile(fichier.location, null, fichier.name, (err) => {
      setTelechargement(false)
      if (err) console.log('err téléchargement', err)
    })
  }

  return (
    <Tooltip label={fichier.name}>
      <Button onClick={telecharger} variant="outline" m={3} disabled={telechargement} colorScheme="blue">
        <Flex align="center" >
          { telechargement && <Spinner colorScheme="blue" mr={2} /> }
          <Img src={logo} h="50px" />
          <Text w="10rem" resize="none" overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">{fichier.name}</Text>
        </Flex>
      </Button>
    </Tooltip>
  )
}