export const sunEditorOptions = {
  buttonList: [
    ['undo', 'redo', 'font', 'fontSize'/* , 'formatBlock' */],
    ['bold', 'underline', 'italic', 'strike'/* , 'subscript', 'superscript' */, 'removeFormat'],
    ['fontColor', 'hiliteColor', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'table'],
    ['link', 'image'/* , 'video', 'fullScreen', 'showBlocks', 'codeView', 'preview', 'print', 'save' */]
  ]
}

export const sunEditorToolbarLang = {
  toolbar: {
    default: 'Par défaut',
    save: 'Enregistrer',
    font: 'Police',
    formats: 'Formats',
    fontSize: 'Taille',
    bold: 'Gras',
    underline: 'Souligné',
    italic: 'Italique',
    strike: 'Barré',
    subscript: 'Indice',
    superscript: 'Exposant',
    removeFormat: 'Effacer format',
    fontColor: 'Couleur text',
    hiliteColor: 'Couleur de fond',
    indent: 'Déplacer à droit',
    outdent: 'Déplacer à gauche',
    align: 'Aligner',
    alignLeft: 'À gauche',
    alignRight: 'À droite',
    alignCenter: 'À centre',
    alignJustify: 'Ajuster',
    list: 'Liste',
    orderList: 'Liste ordonnée',
    unorderList: 'Liste non ordonnée',
    horizontalRule: 'Ligne horizontal',
    hr_solid: 'solid',
    hr_dotted: 'dotted',
    hr_dashed: 'dashed',
    table: 'Table',
    link: 'Lien',
    image: 'Image',
    video: 'Vidéo',
    fullScreen: 'Plein écran',
    showBlocks: 'Afficher blocs',
    codeView: 'Voir le code',
    undo: 'Défaire',
    redo: 'Refaire',
    preview: 'Prévisualiser',
    print: 'Imprimer',
    tag_p: 'Paragraphe',
    tag_div: 'Normal (DIV)',
    tag_h: 'Entête',
    tag_blockquote: 'Citation',
    tag_pre: 'Code',
    template: 'Template'
  },
  dialogBox: {
    linkBox: {
      title: 'Insérer lien',
      url: 'URL du lien',
      text: 'Texte à afficher',
      newWindowCheck: 'Ouvrir dans une nouvelle fenêtre'
    },
    imageBox: {
      title: 'Insérer image',
      file: 'Sélection un fichier',
      url: "URL de l'image",
      altText: 'Texte alternatif'
    },
    videoBox: {
      title: 'Insérer vidéo',
      url: 'Media embed URL, YouTube'
    },
    caption: 'Insert description',
    close: 'Close',
    submitButton: 'Submit',
    revertButton: 'Revert',
    proportion: 'constrain proportions',
    width: 'Width',
    height: 'Height',
    basic: 'Basic',
    left: 'Left',
    right: 'Right',
    center: 'Center'
  },
  controller: {
    edit: 'Modifier',
    remove: 'Effacer',
    insertRowAbove: 'Insérer ligne au-dessus',
    insertRowBelow: 'Insérer ligne en-dessous',
    deleteRow: 'Supprimer ligne',
    insertColumnBefore: 'Insérer colonne avant',
    insertColumnAfter: 'Insérer colonne après',
    deleteColumn: 'Supprimer colonne',
    resize100: 'Dimension 100%',
    resize75: 'Dimension 75%',
    resize50: 'Dimension 50%',
    resize25: 'Dimension 25%',
    mirrorHorizontal: 'Miroir, Horizontal',
    mirrorVertical: 'Miroir, Vertical',
    rotateLeft: 'Pivoter gauche',
    rotateRight: 'Pivoter droite',
    maxSize: 'Max size',
    minSize: 'Min size',
    tableHeader: 'Table header',
    mergeCells: 'Merge cells',
    splitCells: 'Split Cells',
    HorizontalSplit: 'Horizontal split',
    VerticalSplit: 'Vertical split'
  }
}