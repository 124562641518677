import { Avatar } from "@chakra-ui/avatar"
import { useColorMode } from "@chakra-ui/color-mode"
import { useDisclosure } from "@chakra-ui/hooks"
import { Flex, List, ListItem } from "@chakra-ui/layout"
import { Popover, PopoverBody, PopoverContent, PopoverTrigger } from "@chakra-ui/popover"
import { Tag, TagCloseButton, TagLabel } from "@chakra-ui/tag"
import { Children, useEffect, useRef, useState } from "react"
import styles from "../styles/ChampEmails.module.css"
import { emailRegExp } from "../util/email-util"

/**
 * 
 * @param {Array<String>} param0.emails
 * @param {...import("@chakra-ui/layout").FlexProps} param0.props
 * @returns 
 */
export default function ChampEmails({ emails, suggestions, miseAJour, children, ...props }) {
  const { colorMode } = useColorMode()

  const { isOpen, onOpen, onClose } = useDisclosure()

  const [liste, setListe] = useState(new Set(emails))

  const [suggestionsFiltres, setSuggestionsFiltres] = useState(suggestions)

  const refChamp = useRef()

  let [saisie, setSaisie] = useState("")

  const suffixe = Children.toArray(children)
    .find(elt => elt.type === ChampEmailsSuffixe)

  useEffect(() => {
    setSuggestionsFiltres(
      suggestions.filter(elt => {
        return saisie.length && !liste.has(elt) && elt.toLowerCase().includes(saisie)
      })
    )
  }, [saisie, suggestions, liste])

  useEffect(() => {
    if (suggestionsFiltres.length) onOpen()
    else onClose()
  })

  useEffect(() => {
    if (miseAJour) miseAJour([...liste])
  }, [liste, miseAJour])

  function miseAJourListe(evt) {
    const txt = evt.target.textContent.trim()
    let adresse = /<.+>$/.test(txt) ? txt.split(/[<>]/)[1] : txt
    if (emailRegExp.test(adresse)) {
      setListe(new Set([...liste, txt]))
      refChamp.current.innerHTML = ""
    } else if (evt.code !== "Enter") {
      refChamp.current.innerHTML = ""
    }
  }

  function toucheClavierAppuye(evt) {
    if (evt.code === "Enter") {
      evt.preventDefault()
      evt.stopPropagation()
      if (suggestionsFiltres.length)
        appliquerSuggestion(suggestionsFiltres[0])
      else miseAJourListe(evt)
    } else if ((evt.code === "Backspace") && !evt.target.textContent) {
      const tmp = [...liste]
      tmp.pop()
      setListe(new Set(tmp))
    }
  }

  function toucheClavierRelache(evt) {
    setSaisie(evt.target.textContent)
  }

  function champQuitte(evt) {
    miseAJourListe(evt)
    onClose()
  }

  function supprimer(element) {
    setListe(new Set([...liste].filter(elt => elt !== element)))
  }

  function appliquerSuggestion(email) {
    setListe(new Set([...liste, email]))
    refChamp.current.innerHTML = ""
    refChamp.current.focus()
    setSaisie("")
  }

  return (
    <Flex
      {...props}
      align="center"
      pt={1} pb={1}
      wrap="wrap"
      borderWidth="thin" borderTop="none" borderRight="none" borderLeft="none"
      _focusWithin={{ borderColor: colorMode === "light" ? "blue.500" : "blue.300", borderWidth: "2px" }}>
      {[...liste].map((elt, i) => (
        <Tag
          onClick={() => { refChamp.current.focus() }}
          mr={1}
          mt={1}
          size="md"
          key={i}
          borderRadius="full"
          variant="solid"
          colorScheme="blue">
          <Avatar size="xs" name={elt} ml={-1} mr={2} />
          <TagLabel>{elt.split(/\s*</)[0]}</TagLabel>
          <TagCloseButton onClick={() => { supprimer(elt) }} />
        </Tag>
      ))}
      <Flex flex="auto" align="center" mt={1}>
        <Popover initialFocusRef={refChamp} isOpen={isOpen}>
          <PopoverTrigger>
            <span
              ref={refChamp}
              onKeyDown={toucheClavierAppuye}
              onKeyUp={toucheClavierRelache}
              onBlur={champQuitte}
              className={styles.editable}
              contentEditable>
            </span>
          </PopoverTrigger>
          <PopoverContent>
            <PopoverBody>
              <List spacing={1}>
                {suggestionsFiltres.map(elt => (
                  <ListItem
                    onClick={() => { appliquerSuggestion(elt) }}
                    key={elt}
                    fontSize="sm" p={2} borderRadius={5} cursor="pointer"
                    _hover={{ bg: colorMode === "light" ? "gray.100" : "gray.800" }}>
                    {elt}
                  </ListItem>
                ))}
              </List>
            </PopoverBody>
          </PopoverContent>
        </Popover>
        {suffixe}
      </Flex>
    </Flex>
  )
}

export function ChampEmailsSuffixe({ children }) {
  return (
    <>{children}</>
  )
}