import FormulaireMail from "./FormulaireMail"

export default function ReponseMail({ location }) {
  const mail = {
    attachments: [],
    bcc: [],
    cc: [],
    from: "",
    html: "",
    text: "",
    messageId: "",
    oneClickUnsubscribe: true,
    references: [],
    rejected: [],
    subject: "",
    to: [],
    ...location.state.mail,
  }
  return (
    <FormulaireMail mail={mail} />
  )
}