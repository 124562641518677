import { Button, CloseButton, Flex, Input, Link, Select, Text, useColorMode, useToast } from "@chakra-ui/react"
import { useCallback, useEffect, useRef, useState } from "react"
import { Cursor } from "react-bootstrap-icons"
import SunEditor from "suneditor-react"
import DialogueConfirmation from "./DialogueConfirmation"
import 'suneditor/dist/css/suneditor.min.css'
import { sunEditorOptions, sunEditorToolbarLang } from "../util/editor-util"
import { AttachmentIcon, DeleteIcon } from "@chakra-ui/icons"
import { useHistory } from "react-router"
import ChampEmails, { ChampEmailsSuffixe } from "./ChampEmails"
import Telechargement from "./Telechargement"
import { useStore } from "../store"
import DialogueInformation from "./DialogueInformation"
import DialogueAttente from "./DialogueAttente"

export default function FormulaireMail({ mail, display }) {
  const { colorMode } = useColorMode()

  const history = useHistory()

  const toast = useToast()

  const refDialogue = useRef()

  const refDialogueInfo = useRef()

  const refDialogueAttente = useRef()

  const refChampPJ = useRef()

  const mailRef = useRef(mail)

  const [titreDialogue, setTitreDialogue] = useState()

  const [corpsDialogue, setCorpsDialogue] = useState()

  const [libelleAffirmatifDialogue, setLibelleAffirmatifDialogue] = useState()

  const [cc, setCc] = useState()

  const [bcc, setBcc] = useState()

  const [objet, setObjet] = useState(mailRef.current.subject)

  const [fichiersPJ, setFichiersPJ] = useState([])

  const { state } = useStore()

  let adressesMail = state.profil.user ? state.profil.user.mail_addresses : []

  if (mail.repondreA) {
    const repondreA = mail.repondreA.find(elt => adressesMail.includes(elt))
    if (repondreA) {
      adressesMail = [...(new Set([repondreA, ...adressesMail]))]
      mailRef.current.to = mailRef.current.to.filter(elt => elt !== repondreA)
    }
  }

  mailRef.current.from = adressesMail.length ? adressesMail[0] : undefined

  const titreSupprimer = "Supprimer mail"

  const titreObjetAbsent = "Objet absent"

  const suggestions = [
    "Alassane Sarr <alassane.sarr@zuulu.net>",
    "Ibnou Abass Diagne <abass.diagne@zuulu.net>",
    "Boubacar Seydi <boubacar.seydi@pam.com>",
    "Papa Doudou Gueye <papa-doudou.gueye@zuulu.net>",
    "Badara Sarr <badara.sarr@gmail.com>",
    "Groupe Kasy <groupekasy@yahoo.com>",
  ]

  useEffect(() => {
    if (mailRef.current.cc.length) setCc(mailRef.current.cc)
  }, [mailRef, setCc])

  function retourDialogue(resultat) {
    if (resultat) {
      if (titreSupprimer === titreDialogue)
        try {
          history.goBack()
        } catch (err) {
          history.replace("/emails")
        }
      else if (titreDialogue === titreObjetAbsent) envoyer()
    } else {
      setTitreDialogue(null)
      setCorpsDialogue(null)
    }
  }

  function dialogueSupprimer() {
    setTitreDialogue(titreSupprimer)
    setCorpsDialogue(<Text>Voulez-vous vraiment supprimer ce mail ?</Text>)
    setLibelleAffirmatifDialogue("OUI, SUPPRIMER")
    refDialogue.current.ouvrir()
  }

  function dialogueDestinataireAbsent() {
    refDialogueInfo.current.ouvrir()
  }

  function dialogueObjetAbsent() {
    setTitreDialogue(titreObjetAbsent)
    setCorpsDialogue(<Text>Voulez-vous envoyer ce mail sans objet ?</Text>)
    setLibelleAffirmatifDialogue("ENVOYER SANS OBJET")
    refDialogue.current.ouvrir()
  }

  function chargerFichiersPJ(evt) {
    const fichiers = []
    for (const file of evt.target.files) {
      const index = fichiersPJ.findIndex(elt => elt.name === file.name)
      if (index < 0) fichiers.push(file)
    }
    setFichiersPJ([...fichiersPJ, ...fichiers])
    refChampPJ.current.value = ""
  }

  function supprimerPieceJointe(fichier, attachment) {
    setFichiersPJ(fichiersPJ.filter(elt => elt !== fichier))
    if (mailRef.current.attachments)
      mailRef.current.attachments = mailRef.current.attachments.filter(elt => elt !== attachment)
  }

  const ajouterPieceJointe = useCallback(function (attachment) {
    mailRef.current.attachments.push(attachment)
  }, [])

  function miseAJourMessage(htmlContent) {
    mailRef.current.html = htmlContent
  }

  function miseAJourObjet(event) {
    mailRef.current.subject = event.target.value
    setObjet(mailRef.current.subject)
  }

  function miseAJourEnvoyeur(event) {
    mailRef.current.from = event.target.value
  }

  async function envoyer() {
    if (!mailRef.current.to.length) dialogueDestinataireAbsent()
    else if (!mailRef.current.subject && (titreDialogue !== titreObjetAbsent))
      dialogueObjetAbsent()
    else {
      refDialogueAttente.current.ouvrir(60000)
      try {
        const res = await fetch("/mails", {
          method: "POST",
          body: JSON.stringify(mailRef.current),
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json"
          }
        })
        const mailEnvoye = await res.json()
        history.replace(`/emails/messages-envoyes?id=${mailEnvoye._id}`)
      } catch (err) {
        console.log("err FormulaireMail > envoyer", err);
        toast({
          description: err.wsMessage || "L'envoi du mail a échoué, veuillez vérifier votre connexion Internet et réessayer",
          status: "error",
          duration: 5000,
          isClosable: true,
        })
      }
      if (refDialogueAttente.current) refDialogueAttente.current.fermer()
    }
  }

  return (
    <Flex display={display} direction="column" h="100%" resize="none"
      overflow="auto" p={5} m={1} pb={8} borderRadius={5} flex="1"
      bg={colorMode === "light" ? "gray.50" : "whiteAlpha.100"}>
      <Flex justify="space-between" align="center">
        <Button onClick={envoyer} variant="ghost" leftIcon={<Cursor />} colorScheme="blue">Envoyer</Button>
        <Button onClick={dialogueSupprimer} variant="ghost" leftIcon={<DeleteIcon />}>Supprimer</Button>
        <Button
          onClick={() => { refChampPJ.current.click() }}
          variant="ghost"
          leftIcon={<AttachmentIcon />}>
          Joindre
        </Button>
      </Flex>
      <Flex align="center" mt={3}>
        <Text mr={3}>De</Text>
        <Select variant="flushed" onChange={miseAJourEnvoyeur} disabled={!!mailRef.current.repondreA}>
          {adressesMail.map(elt => (
            <option key={elt} value={elt}>{elt}</option>
          ))}
        </Select>
      </Flex>
      <Flex align="center" mt={3}>
        <Text mr={3}>À</Text>
        <ChampEmails w="100%" emails={mailRef.current.to} suggestions={suggestions}
          miseAJour={to => { mailRef.current.to = to }}>
          <ChampEmailsSuffixe>
            <Flex>
              {!cc && <Link ml={3} onClick={() => { setCc([]) }}>Cc</Link>}
              {!bcc && <Link ml={3} onClick={() => { setBcc([]) }}>Bcc</Link>}
            </Flex>
          </ChampEmailsSuffixe>
        </ChampEmails>
      </Flex>
      {cc && <Flex align="center" mt={3}>
        <Text mr={3}>Cc</Text>
        <ChampEmails w="100%" emails={mailRef.current.cc} suggestions={suggestions}
          miseAJour={cc => { mailRef.current.cc = cc }}>
          <ChampEmailsSuffixe>
            <Flex>
              <CloseButton onClick={() => { setCc(null); mailRef.current.cc = [] }} />
            </Flex>
          </ChampEmailsSuffixe>
        </ChampEmails>
      </Flex>}
      {bcc && <Flex align="center" mt={3}>
        <Text mr={3}>Bcc</Text>
        <ChampEmails w="100%" suggestions={suggestions}
          miseAJour={bcc => { mailRef.current.bcc = bcc }}>
          <ChampEmailsSuffixe>
            <Flex>
              <CloseButton onClick={() => { setBcc(null); mailRef.current.bcc = [] }} />
            </Flex>
          </ChampEmailsSuffixe>
        </ChampEmails>
      </Flex>}
      <Flex align="center" mt={3}>
        <Text mr={3}>Objet</Text>
        <Input value={objet} onChange={miseAJourObjet} variant="flushed" placeholder="Objet" />
      </Flex>
      <Flex mt={3} align="center" wrap="wrap">
        {fichiersPJ.map(elt => (
          <Telechargement fichier={elt} key={elt.name}
            actionSupprimer={supprimerPieceJointe}
            telechargementEffectue={ajouterPieceJointe} />
        ))}
      </Flex>
      <Flex mt={7}>
        <SunEditor defaultValue={mailRef.current.html} height="350" autoFocus={true}
          setOptions={sunEditorOptions}
          lang={sunEditorToolbarLang}
          onChange={miseAJourMessage} />
      </Flex>
      <DialogueConfirmation
        ref={refDialogue}
        titre={titreDialogue}
        libelleAffirmatif={libelleAffirmatifDialogue}
        retour={retourDialogue}>
        {corpsDialogue}
      </DialogueConfirmation>
      <DialogueInformation
        ref={refDialogueInfo}
        titre="Destinataire absent">
        <Text>Veuillez saisir l'adresse (les adresses) e-mail du destinataire (des destinataires) </Text>
      </DialogueInformation>
      <DialogueAttente
        ref={refDialogueAttente}
        titre="Envoi du mail ...">
      </DialogueAttente>
      <input
        type="file"
        multiple
        ref={refChampPJ}
        onChange={chargerFichiersPJ}
        style={{ display: "none" }} />
    </Flex>
  )
}