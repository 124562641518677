import { CloseButton } from "@chakra-ui/close-button"
import { useDisclosure } from "@chakra-ui/hooks"
import { Img } from "@chakra-ui/image"
import { Flex } from "@chakra-ui/layout"
import { Drawer, DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay } from "@chakra-ui/modal"
import { useToast } from "@chakra-ui/toast"
import { useEffect, useState } from "react"
import { Route, Switch, useRouteMatch } from "react-router"
import io from "socket.io-client"
import BoiteDeReception from "../components/BoiteDeReception"
import CreationMail from "../components/CreationMail"
import Entete from "../components/Entete"
import MenuPrincipal from "../components/MenuPrincipal"
import MessagesEnvoyes from "../components/MessagesEnvoyes"
import ReponseMail from "../components/ReponseMail"
import { useStore } from "../store"
import { useScreenMoreThan, useScreenLessThan } from "../util/screen-size-util"
import '../firebase'
import { getFirebaseMessagingToken, listenForFirebaseMessage } from "../firebase"

export default function Emails() {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const screenMoreThanMd = useScreenMoreThan('md')

  const screenLessThanLg = useScreenLessThan('lg')

  const { dispatch } = useStore()

  const { url } = useRouteMatch()

  const toast = useToast()

  const [errorMessage, setErrorMessage] = useState()

  const [fcmTokenObtenu, setFcmTokenObtenu] = useState(1)

  useEffect(() => {
    console.log(`Récupération du token Firebase Cloud Messaging [${fcmTokenObtenu}]`)
    let delegate
    getFirebaseMessagingToken((err, firebaseCloudMessagingToken) => {
      if (err) console.log("Erreur récupération du token Firebase Cloud Messaging", err)
      else if (firebaseCloudMessagingToken) {
        fetch("/set-firebase-cloud-messaging-token", {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
          },
          body: JSON.stringify({ firebaseCloudMessagingToken })
        }).then(res => {
          if (res.status !== 200) throw res.json()
        }).catch(err => {
          console.log("Erreur enregistrement du token [firebaseCloudMessagingToken]", err)
        })
      }
      else delegate = setTimeout(() => {
        setFcmTokenObtenu(fcmTokenObtenu + 1)
      }, 5000)
    })
    return () => {
      if (delegate) clearTimeout(delegate)
    }
  }, [fcmTokenObtenu])

  useEffect(() => {
    listenForFirebaseMessage(payload => {
      const { title, body } = payload.data
      toast({
        title,
        description: body,
        status: "info",
        duration: 9000,
        isClosable: true,
      })
    })
  }, [toast])

  useEffect(() => {
    fetch("/getprofile", { headers: { Accept: "application/json" } })
      .then(res => {
        if (res.status === 200) return res.json()
        else throw res.json()
      })
      .then(profil => {
        dispatch({ type: 'profil', profil })
      })
      .catch(err => {
        const title = "Erreur chargement de profil"
        const message = err && err.wsMessage
          ? err.wsMessage
          : "Le chargement de votre profil a échoué, veuillez vérifier votre connexion internet."
        setErrorMessage({ title, message })
      })
  }, [dispatch])

  useEffect(() => {
    if (errorMessage) toast({
      title: errorMessage.title,
      description: errorMessage.message,
      status: "error",
      duration: 9000,
      isClosable: true,
    })
  }, [errorMessage, toast])

  useEffect(() => {
    const socket = io()
    socket.on("connect_error", (err) => {
      console.log("connect_error", err)
    })
    socket.on("unread", (nonLus) => {
      dispatch({ type: 'nonLus', nonLus })
    })
    socket.on("unread-decrement", (_payload) => {
      dispatch({ type: 'decrementerNonLus' })
    })
    socket.on("new-mail", (_payload) => {
      dispatch({ type: 'incrementerNonLus' })
    })
    return () => {
      socket.disconnect()
    }
  }, [dispatch])

  return (
    <>
      <Flex h="100vh" resize="none" overflow="hidden" direction="column">
        <Entete onOpen={onOpen} />
        <Flex direction="row" flex="1" resize="none" overflow="hidden">
          {screenMoreThanMd && <MenuPrincipal />}
          <Switch>
            <Route path={`${url}/boite-de-reception`}>
              <BoiteDeReception />
            </Route>
            <Route path={`${url}/messages-envoyes`} component={MessagesEnvoyes} />
            <Route path={`${url}/nouveau`} component={CreationMail} />
            <Route path={`${url}/reponse`} component={ReponseMail} />
          </Switch>
        </Flex>
      </Flex>
      <Drawer placement="left" onClose={onClose} isOpen={isOpen && screenLessThanLg}>
        <DrawerOverlay>
          <DrawerContent p={1}>
            <DrawerHeader borderBottomWidth="1px">
              <Flex>
                <CloseButton onClick={onClose} mr={3} />
                <Img src="/logo_sunumail.png" alt="Logo Sunumail" w={[24, 28, 32]} objectFit="scale-down" />
              </Flex>
            </DrawerHeader>
            <DrawerBody p={1}>
              <MenuPrincipal onClose={onClose} />
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </>
  )
}