import { Badge, Button, Flex, Link, Text, useColorMode } from "@chakra-ui/react";
import { Archive, BoxArrowUp, Cursor, ExclamationOctagon, FileEarmarkText, Inbox, Pen } from "react-bootstrap-icons";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { useStore } from "../store";
import styles from "../styles/MenuPrincipal.module.css";

export default function MenuPrincipal({ onClose }) {
  const history = useHistory()

  const { state } = useStore()

  return (
    <Flex h="100%" p={2} direction="column" resize="none" overflow="auto">
      <Button onClick={() => { history.push("/emails/nouveau"); if (onClose) onClose() }} m={1} colorScheme="teal" leftIcon={<Pen />} variant="outline">Nouveau message</Button>
      <LienMenu
        onClose={onClose}
        icon={<Inbox size={18} />}
        libelle="Boîte de réception"
        chemin="boite-de-reception"
        nombre={state.nonLus}
        couleur="red" />
      <LienMenu
        onClose={onClose}
        icon={<Cursor size={18} />}
        libelle="Messages envoyés"
        chemin="messages-envoyes" />
      <LienMenu
        onClose={onClose}
        icon={<FileEarmarkText size={18} />}
        libelle="Brouillons"
        nombre="1"
        chemin="brouillons" />
      <LienMenu
        onClose={onClose}
        icon={<ExclamationOctagon size={18} />}
        libelle="Spams"
        nombre="6"
        couleur="yellow"
        chemin="spams" />
      <LienMenu
        onClose={onClose}
        icon={<BoxArrowUp size={18} />}
        libelle="Boite d'envoi"
        chemin="boite-envoi" />
      <LienMenu
        onClose={onClose}
        icon={<Archive size={18} />}
        libelle="Archives"
        chemin="archives" />
    </Flex>
  )
}

function LienMenu({ icon, libelle, chemin, nombre, couleur, onClose }) {
  const { pathname } = useLocation()
  const { colorMode } = useColorMode()
  const styleLienMenu = pathname === `/emails/${chemin}`
    ? styles.lienMenuActif : colorMode === 'light'
      ? styles.lienMenu : styles.lienMenuSombre
  chemin = chemin || '#'
  return (
    <Link as={NavLink} to={chemin} className={styleLienMenu} onClick={() => { if (onClose) onClose() }}>
      {icon}
      <Text ml={4}>{libelle}</Text>
      { nombre
        ? <Badge variant="solid" colorScheme={couleur} ml={2}>{nombre}</Badge>
        : ''}
    </Link>
  )
}