import { Divider, Flex, Text } from "@chakra-ui/layout"
import { useEffect } from "react"
import Entete from "../components/Entete"
import FormulaireConnexion from "../components/FormulaireConnexion"
import styles from '../styles/Accueil.module.css'

export default function Accueil() {
  useEffect(() => {
    document.title = "Accueil Sunumail"
  })

  return (
    <div className={styles.container}>
      <Entete estPageLogin={true} />
      <Divider />
      <main className={styles.main}>
        <Flex w="100%" wrap="wrap">
          <Flex flex="1" align="center" justify="center" h="100vh"
            minW="320px" background="linear-gradient(to bottom right, #285e619e, #319795c7), url(/mails_background.png) center center no-repeat" >
            <Text
              bgGradient="linear(to-l, #28ca5a, #b4ff00)"
              bgClip="text"
              fontSize="6xl"
              fontWeight="extrabold"
            >
              Sunumail
            </Text>
          </Flex>
          <Flex id="login_form" flex="1" align="center" justify="center" h="100vh" minW="320px">
            <FormulaireConnexion />
          </Flex>
        </Flex>
      </main>
      <Divider />
      <footer className={styles.footer}>
        <a href="https://sunucom.com" target="_blank" rel="noreferrer" >
          Powered by&nbsp;
          <img src="/logo_sunucom.png" alt="Sunumail Logo" className={styles.logo} />
        </a>
      </footer>
    </div>
  )
}