import { useDisclosure } from "@chakra-ui/hooks"
import { Flex, Text } from "@chakra-ui/layout"
import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/modal"
import { Spinner } from "@chakra-ui/spinner"
import { forwardRef, useImperativeHandle } from "react"

export default forwardRef(({ titre }, ref) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  useImperativeHandle(ref, () => ({
    ouvrir(timeout) {
      onOpen()
      if (Number.isInteger(timeout))
        setTimeout(onClose, timeout)
    },
    fermer() {
      onClose()
    }
  }))

  return (
    <Modal size="sm" isCentered={true} isOpen={isOpen} closeOnOverlayClick={false} closeOnEsc={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <Flex direction="column" align="center">
            <Spinner size="lg" m={5} />
            <Text>{titre}</Text>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
})