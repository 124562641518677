import { useToast } from "@chakra-ui/toast"
import { useEffect, useReducer, useState } from "react"
import { useScreenLessThan } from "../util/screen-size-util"
import Mail from "./Mail"
import MailList from "./MailList"

const wsUrl = "/mails"

export default function MessagesEnvoyes() {
  const [selectedMail, setSelectedMail] = useState()

  const [selectedId, setSelectedId] = useState()

  function mailsReducer(state, action) {
    switch (action.type) {
      case "ajouter":
        return [...state, ...action.mails]
      case "supprimer":
        return state.filter(elt => elt._id !== action.id)
      default:
        break
    }
  }

  const [mails, dispatch] = useReducer(mailsReducer, [])

  const [total, setTotal] = useState(0)

  const [page, setPage] = useState(0)

  const [chargementEnCours, setChargementEnCours] = useState(false)

  const screenLessThanMd = useScreenLessThan('md')

  const toast = useToast()

  const chargerPlus = () => {
    if (!chargementEnCours && page * 20 < total) setPage(page + 1)
  }

  const onMailSelected = (i) => {
    setSelectedMail(mails[i])
    try { fetch(`${wsUrl}/${mails[i]._id}`) } catch (err) { } // Marquer le mail comme lu coté serveur
  }

  const onBackClick = () => {
    setSelectedMail(null)
  }

  const supprimerMailAffiche = () => {
    try { fetch(`${wsUrl}/${selectedMail._id}`, { method: "DELETE" }) } catch (err) { }
    toast({
      title: "Mail supprimé",
      description: selectedMail.subject,
      status: "info",
      duration: 9000,
      isClosable: true,
    })
    dispatch({ type: 'supprimer', id: selectedId })
    setSelectedMail(null)
  }

  useEffect(() => {
    setSelectedId(selectedMail ? selectedMail._id : null)
  }, [selectedMail])

  useEffect(() => {
    setChargementEnCours(true)
    fetch(`${wsUrl}/paginated`, {
      method: "POST",
      body: JSON.stringify({ skip: page * 20, limit: 20 }),
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      }
    }).then(res => {
      if (res.status === 200) return res.json()
      else throw res.json()
    }).then(result => {
      setChargementEnCours(false)
      dispatch({ type: 'ajouter', mails: result.mails })
      if (result.total) setTotal(result.total)
    }).catch(err => {
      setChargementEnCours(false)
      const msg = err && err.wsMessage
        ? err.wsMessage
        : "La récupération des mails a échoué, veuillez vérifier votre connexion internet."
      console.log("Echec du chargement des mails envoyés", msg)
    })
  }, [page])

  return (
    <>
      <MailList mails={mails} onBottomReached={chargerPlus} loading={chargementEnCours} onMailSelected={onMailSelected} selectedId={selectedId} display={screenLessThanMd && selectedMail ? "none" : "flex"} />
      <Mail mail={selectedMail} onBackClick={onBackClick} actionSupprimer={supprimerMailAffiche} display={screenLessThanMd && !selectedMail ? "none" : "flex"} />
    </>
  )
}