import { Avatar, Badge, Center, Flex, IconButton, Menu, MenuButton, MenuItem, MenuList, Tag, TagLabel, Text, Tooltip, useColorMode } from "@chakra-ui/react"
import { useRef, useState } from "react"
import { Archive, ArrowLeft, ExclamationOctagon, Flag, Forward, Reply, ReplyAll, ThreeDots, Trash } from "react-bootstrap-icons"
import { useHistory } from "react-router"
import { emailDate } from "../util/date-util"
import { processHtml } from "../util/html-util"
import DialogueConfirmation from "./DialogueConfirmation"
import PieceJointe from "./PieceJointe"

export default function Mail({ mail, onBackClick, actionSupprimer, display }) {
  const { colorMode } = useColorMode()

  const refDialogue = useRef()

  const [titreDialogue, setTitreDialogue] = useState()

  const [corpsDialogue, setCorpsDialogue] = useState()

  const [libelleAffirmatifDialogue, setLibelleAffirmatifDialogue] = useState()

  const history = useHistory()

  const titreSupprimer = "Supprimer mail"

  function backButtonHandler() {
    if (onBackClick) onBackClick()
    onBackClick = null
  }

  function retourDialogue(resultat) {
    if (!resultat) return
    if (titreSupprimer === titreDialogue) {
      actionSupprimer()
      actionSupprimer = null
    }
  }

  function dialogueSupprimer() {
    setTitreDialogue(titreSupprimer)
    setCorpsDialogue(<Text>Voulez-vous vraiment supprimer ce mail ?</Text>)
    setLibelleAffirmatifDialogue("OUI, SUPPRIMER")
    refDialogue.current.ouvrir()
  }

  function repondre() {
    history.push(`/emails/reponse`, {
      mail: {
        repondreA: [mail.from, ...mail.to],
        to: [mail.from],
        cc: [],
        bcc: [],
        subject: /^R[Ee]\s*:/.test(mail.subject) ? mail.subject : `RE : ${mail.subject}`,
        html: `<br/><br/><p><b>${mail.from.split("<").shift()} a écrit le ${emailDate(mail.date, { full: true })} :</b></p><blockquote style="margin-left: 5px; padding-left: 5px; border-left: 2px solid #ddd">${mail.html}</blockquote>`,
      }
    })
  }

  function repondreTous() {
    history.push(`/emails/reponse`, {
      mail: {
        repondreA: [mail.from, ...mail.to],
        to: [mail.from, ...mail.to],
        cc: mail.cc,
        bcc: [],
        subject: /^R[Ee]\s*:/.test(mail.subject) ? mail.subject : `RE : ${mail.subject}`,
        html: `<br/><br/><p><b>${mail.from.split("<").shift()} a écrit le ${emailDate(mail.date, { full: true })} :</b></p><blockquote style="margin-left: 5px; padding-left: 5px; border-left: 2px solid #ddd">${mail.html}</blockquote>`,
      }
    })
  }

  function transferer() {
    history.push(`/emails/reponse`, {
      mail: {
        repondreA: [mail.from],
        to: [mail.from],
        cc: [],
        bcc: [],
        subject: mail.subject.startsWith("TR :") ? mail.subject : `TR : ${mail.subject}`,
        html: `<br/><br/><blockquote style="margin-left: 5px; padding-left: 5px; border-left: 2px solid #ddd"><p><b>Date :</b> ${emailDate(mail.date, { full: true })}</p><p><b>De :</b> ${mail.from}</p><p><b>À :</b> ${mail.to.join(", ")}</p>${mail.cc.length ? `<p><b>Cc :</b> ${mail.cc.join(", ")}</p>` : ""}${mail.bcc.length ? `<p><b>Bcc :</b> ${mail.bcc.join(", ")}</p>` : ""}<br/>${mail.html}</blockquote>`,
      }
    })
  }

  return (
    <Flex display={display} direction="column" h="100%" resize="none"
      overflow="auto" p={5} m={1} pb={8} borderRadius={5} flex="1"
      bg={colorMode === "light" ? "gray.100" : "whiteAlpha.100"}>
      {mail ? <>
        <Flex justify="space-between" align="center">
          <Tooltip label="Retour">
            <IconButton fontSize="1.4rem" onClick={backButtonHandler} icon={<ArrowLeft />} backgroundColor="transparent" />
          </Tooltip>
          <Flex>
            <Tooltip label="Répondre">
              <IconButton onClick={repondre} icon={<Reply />} backgroundColor="transparent" />
            </Tooltip>
            <Tooltip label="Répondre à tous">
              <IconButton onClick={repondreTous} icon={<ReplyAll />} backgroundColor="transparent" />
            </Tooltip>
            <Tooltip label="Transférer">
              <IconButton onClick={transferer} icon={<Forward />} backgroundColor="transparent" />
            </Tooltip>
            <Menu>
              <MenuButton
                backgroundColor="transparent"
                as={IconButton}
                aria-label="Options"
                icon={<ThreeDots />}
              />
              <MenuList>
                <MenuItem icon={<Archive />}>Archiver</MenuItem>
                <MenuItem icon={<ExclamationOctagon />}>Signaler comme spam</MenuItem>
                <MenuItem icon={<Trash />} onClick={dialogueSupprimer}>Supprimer</MenuItem>
                <MenuItem icon={<Flag />}>Marquer comme important</MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </Flex>
        <Text fontSize="large" fontWeight="bold">{mail.subject}</Text>
        <Badge alignSelf="flex-end" colorScheme="blue">{emailDate(mail.date, { full: true })}</Badge>
        <Flex direction="row" alignItems="center" mt={2}>
          <Avatar size="md" name={mail.from.split(/\s*</)[0]} />
          <Text ml={2} fontWeight="bold">{mail.from}</Text>
        </Flex>
        <Flex mt={3} align="center" wrap="wrap">
          <Text fontSize="large" fontWeight="bold" m={2} >À</Text>
          {mail.to.map((elt, i) => (
            <Tag key={i} size="lg" colorScheme="blue" borderRadius="full" m={1}>
              <Avatar
                size="xs"
                name={elt}
                ml={-1}
                mr={2}
              />
              <TagLabel fontSize="sm">{elt}</TagLabel>
            </Tag>
          ))}
        </Flex>
        <Flex display={mail.cc.length ? "flex" : "none"} mt={3} align="center" wrap="wrap">
          <Text fontSize="large" fontWeight="bold" m={2} >Cc</Text>
          {mail.cc.map((elt, i) => (
            <Tag key={i} size="lg" colorScheme="blue" borderRadius="full" m={1}>
              <Avatar
                size="xs"
                name={elt}
                ml={-1}
                mr={2}
              />
              <TagLabel fontSize="sm">{elt}</TagLabel>
            </Tag>
          ))}
        </Flex>
        <Flex display={mail.bcc.length ? "flex" : "none"} mt={3} align="center" wrap="wrap">
          <Text fontSize="large" fontWeight="bold" m={2} >Cc</Text>
          {mail.cc.map((elt, i) => (
            <Tag key={i} size="lg" colorScheme="blue" borderRadius="full" m={1}>
              <Avatar
                size="xs"
                name={elt}
                ml={-1}
                mr={2}
              />
              <TagLabel fontSize="sm">{elt}</TagLabel>
            </Tag>
          ))}
        </Flex>
        <Flex mt={3} align="center" wrap="wrap">
          {mail.attachments.map((elt, i) => (
            <PieceJointe fichier={elt} key={i} />
          ))}
        </Flex>
        <Flex mt={7} m="auto">
          {
            mail.html
              ? <Text dangerouslySetInnerHTML={{ __html: processHtml(mail.html) }} />
              : <Text whiteSpace="pre-line">{mail.text}</Text>
          }
        </Flex>
      </> : <Center w="100%" h="100%">Aucun mail sélectionné</Center>}
      <DialogueConfirmation
        ref={refDialogue}
        titre={titreDialogue}
        libelleAffirmatif={libelleAffirmatifDialogue}
        retour={retourDialogue}>
        {corpsDialogue}
      </DialogueConfirmation>
    </Flex>
  )
}