import firebase from 'firebase/app'
import 'firebase/messaging'

const firebaseConfig = {
  apiKey: "AIzaSyAo6RSf1AWI5MauQ6cHdqt-CS6DCk99l6Y",
  authDomain: "sunumail-c803a.firebaseapp.com",
  databaseURL: "https://sunumail-c803a-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "sunumail-c803a",
  storageBucket: "sunumail-c803a.appspot.com",
  messagingSenderId: "803130222564",
  appId: "1:803130222564:web:bf7baed14a0e698af8c9f1",
  measurementId: "G-1QXY7QDTGV"
}

firebase.initializeApp(firebaseConfig)

const vapidKey = "BCbiPQMUkAk4U_-spX7zPq4sR1TbUv6leIzagfSpC13bwWyC2mq3HJtNVKBg8yz3ugzVmffckLVWmRuFRgPD9X4"

export async function getFirebaseMessagingToken(callback) {
  return firebase.messaging().getToken({ vapidKey }).then((currentToken) => {
    if (currentToken) {
      callback(null, currentToken)
    } else {
      callback()
    }
  }).catch((err) => {
    callback(err)
  })
}

export function listenForFirebaseMessage(callback) {
  firebase.messaging().onMessage(function (payload) {
    callback(payload)
  });
}
