export function processHtml(html) {
  const div = document.createElement('div')
  div.innerHTML = html
  const links = div.querySelectorAll('a').values()
  for (const link of links) link.target = "_blank"
  const scripts = div.querySelectorAll('script').values()
  for (const script of scripts) script.remove()
  const styles = div.querySelectorAll('style').values()
  for (const style of styles) style.remove()
  return div.innerHTML
}