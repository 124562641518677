import { IconButton } from "@chakra-ui/button"
import { CloseIcon, RepeatIcon } from "@chakra-ui/icons"
import { Img } from "@chakra-ui/image"
import { Box, Flex, Text } from "@chakra-ui/layout"
import { Spinner } from "@chakra-ui/spinner"
import { Tooltip } from "@chakra-ui/tooltip"
import { useCallback, useEffect, useRef, useState } from "react"

export default function Telechargement({ fichier, actionSupprimer, telechargementEffectue }) {
  const extension = fichier.name.split('.').pop()
  let logo;
  switch (extension) {
    case "xlsx":
    case "xls":
      logo = "/xls.png"
      break
    case "docx":
    case "doc":
      logo = "/doc.png"
      break
    case "pdf":
      logo = "/pdf.png"
      break
    case "pptx":
    case "ppt":
      logo = "/ppt.png"
      break
    case "rar":
    case "zip":
      logo = "/zip.png"
      break
    case "txt":
      logo = "/txt.png"
      break
    case "bmp":
    case "gif":
    case "ico":
    case "jpeg":
    case "jpg":
    case "png":
      logo = "/image.png"
      break
    default:
      logo = "/file.png"
  }

  const attachmentRef = useRef();

  const [telechargement, setTelechargement] = useState(false)

  const [echoue, setEchoue] = useState(false)

  const telecharger = useCallback(async function () {
    setEchoue(false)
    setTelechargement(true)
    const form = new FormData()
    form.append("file", fichier, fichier.name)
    try {
      const res = await fetch("/attachments", {
        method: "POST",
        body: form
      })
      switch (res.status) {
        case 200:
          attachmentRef.current = (await res.json())[0]
          telechargementEffectue(attachmentRef.current)
          break;
        case 413:
          setEchoue(true)
          alert("Pièce jointe trop volumineuse")
          break;
        default:
          setEchoue(true)
          break;
      }
    } catch (err) {
      console.log("err", err);
    }
    setTelechargement(false)
  }, [fichier, telechargementEffectue])

  function supprimer() {
    if (attachmentRef.current) {
      try {
        fetch(`/attachments/${attachmentRef.current._id}`, { method: "DELETE" })
      } catch (err) {
        console.log("err", err);
      }
    }
    actionSupprimer(fichier, attachmentRef.current)
  }

  useEffect(() => {
    telecharger()
  }, [telecharger])

  return (
    <Tooltip label={fichier.name}>
      <Box variant="outline" m={3} p={2} borderWidth="thin"
        borderColor={echoue ? "red.400" : null}
        borderRadius="lg" >
        <Flex align="center" >
          {telechargement && <Spinner colorScheme="blue" mr={2} />}
          <Img src={logo} h="50px" />
          <Flex direction="column">
            <Text w="10rem" resize="none" overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">{fichier.name}</Text>
            {echoue && <Text textColor="red.400" fontSize="sm" fontWeight="bold">Échec téléchargement</Text>}
          </Flex>
          <Tooltip label="Annuler">
            <IconButton onClick={supprimer} colorScheme="red" variant="ghost" borderRadius="3xl" icon={<CloseIcon fontSize="sm" />} />
          </Tooltip>
          {echoue && <Tooltip label="Réessayer">
            <IconButton onClick={telecharger} colorScheme="blue" variant="ghost" borderRadius="3xl" icon={<RepeatIcon fontSize="lg" />} />
          </Tooltip>}
        </Flex>
      </Box>
    </Tooltip>
  )
}