function downloadBlob(blob, filename) {
  const url = URL.createObjectURL(blob)
  const a = document.createElement("a")
  a.href = url
  a.download = filename || "download"
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url)
      a.removeEventListener("click", clickHandler)
    }, 150)
  }
  a.addEventListener("click", clickHandler, false)
  a.click()
  return a
}

export async function downloadFile(url, options, filename, callback) {
  try {
    const res = await fetch(url, options)
    if (res.status === 200) {
      const blob = await res.blob()
      downloadBlob(blob, filename)
      callback()
    } else throw await res.json()
  } catch (err) {
    console.log(`Echec du téléchargement ${filename}`)
    console.log("err", err)
    callback(err)
  }
}