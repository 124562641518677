import { Button, Flex, FormControl, FormErrorMessage, FormLabel, Input, Text, useToast } from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import { useHistory } from "react-router";

export default function FormulaireConnexion() {
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i

  const history = useHistory()

  const toast = useToast()

  function valideEmail(value) {
    if (!value)
      return "Adresse e-mail requis"
    else if (!emailRegex.test(value))
      return "Adresse email invalide"
  }

  function valideMotDePasse(value) {
    if (!value)
      return "Mot de passe requis"
    else if (value.length < 6)
      return "Mot de passe trop court"
  }

  function messageErreur(message) {
    toast({
      title: "Echec de connexion",
      description: message,
      status: "error",
      duration: 9000,
      isClosable: true,
    })
  }

  async function authentifier(values, _actions) {
    try {
      const res = await fetch('/authenticate', {
        method: 'POST',
        body: JSON.stringify(values),
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        }
      })
      if (res.status === 200) {
        history.replace('/emails/boite-de-reception')
      } else {
        throw await res.json()
      }
    } catch (err) {
      const msg = err && err.wsMessage
        ? err.wsMessage
        : `La tentative de connexion a échoué, veuillez vérifier votre connexion internet.`
      messageErreur(msg)
    }
  }

  return (
    <Formik
      initialValues={{ email: '', pwd: '' }}
      onSubmit={authentifier}>
      {props => (
        <Form noValidate={true}>
          <Flex minW="300px" direction="column">
            <Text textAlign="center" fontSize="6xl" mb={5}>
              Connexion
            </Text>
            <Field name="email" validate={valideEmail}>
              {({ field, form }) => (
                <FormControl mb={3} id="email" isInvalid={form.errors.email && form.touched.email}>
                  <FormLabel>Adresse e-mail</FormLabel>
                  <Input {...field} type="email" />
                  <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="pwd" validate={valideMotDePasse}>
              {({ field, form }) => (
                <FormControl mb={3} id="pwd" isInvalid={form.errors.pwd && form.touched.pwd}>
                  <FormLabel>Mot de passe</FormLabel>
                  <Input {...field} type="password" />
                  <FormErrorMessage>{form.errors.pwd}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Button
              colorScheme="teal"
              isLoading={props.isSubmitting}
              type="submit"
              mt={4}>Se connecter</Button>
          </Flex>
        </Form>
      )}
    </Formik>
  )
}