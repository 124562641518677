import { HamburgerIcon, MoonIcon, SearchIcon, SunIcon } from "@chakra-ui/icons"
import { Avatar, Box, Button, Flex, IconButton, Img, Input, InputGroup, InputLeftElement, LinkBox, LinkOverlay, Menu, MenuButton, MenuItem, MenuList, Spacer, useColorMode, useToast } from "@chakra-ui/react"
import { BoxArrowLeft, Person } from "react-bootstrap-icons"
import { useHistory } from "react-router"
import { useStore } from "../store"
import { useScreenLessThan } from "../util/screen-size-util"

export default function Entete({ estPageLogin, onOpen }) {
  const { colorMode, toggleColorMode } = useColorMode()
  
  const screenLessThanLg = useScreenLessThan('lg')

  const history = useHistory()

  const toast = useToast()

  const { state } = useStore()

  const nomComplet = state.profil.user ? `${state.profil.user.prenom} ${state.profil.user.nom}` : ''

  function messageErreur(title, description) {
    toast({
      title,
      description,
      status: "error",
      duration: 9000,
      isClosable: true,
    })
  }

  async function deconnexion() {
    try {
      const res = await fetch('/logout')
      if (res.status === 200) {
        history.replace('/')
      } else {
        throw await res.json()
      }
    } catch (err) {
      const msg = err && err.wsMessage
        ? err.wsMessage
        : "La tentative de déconnexion a échoué, veuillez vérifier votre connexion internet."
      messageErreur("Echec de la déconnexion", msg)
    }
  }

  return (
    <Flex top="0" w="100%" p={2} align="center">
      {screenLessThanLg && !estPageLogin ? <IconButton onClick={onOpen} icon={<HamburgerIcon />} backgroundColor="transparent" /> : ""}
      <Box p={1}>
        <Img src="/logo_sunumail.png" alt="Logo Sunumail" w={[24, 28, 32]} />
      </Box>
      <Spacer />
      {!estPageLogin &&
        <Box flex="auto">
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              children={<SearchIcon />}
            />
            <Input type="tel" placeholder="Chercher" variant="filled" />
          </InputGroup>
        </Box>
      }
      <Spacer />
      <IconButton
        backgroundColor="transparent"
        onClick={toggleColorMode}
        icon={colorMode === "light" ? <MoonIcon /> : <SunIcon />}
        mr={3} />
      {estPageLogin ? <LinkBox>
        <LinkOverlay href="#login_form" mr={3}>
          <Button colorScheme="teal">Connexion</Button>
        </LinkOverlay>
      </LinkBox> :
        <Menu>
          <MenuButton
            backgroundColor="transparent"
            as={IconButton}
            aria-label="Options"
            icon={<Avatar size="sm" name={nomComplet} />}
          />
          <MenuList>
            <MenuItem icon={<Person />}>{nomComplet}</MenuItem>
            <MenuItem onClick={deconnexion} icon={<BoxArrowLeft />}>Se déconnecter</MenuItem>
          </MenuList>
        </Menu>
      }
    </Flex>
  )
}