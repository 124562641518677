import './App.css'
import { ChakraProvider } from '@chakra-ui/react'
import Accueil from './pages/accueil'
import { Route, Switch } from 'react-router'
import { StoreProvider } from './store'
import Emails from './pages/emails'

function App() {

  return (
    <ChakraProvider>
      <StoreProvider>
        <Switch>
          <Route path="/" component={Accueil} exact />
          <Route path="/emails" component={Emails} />
        </Switch>
      </StoreProvider>
    </ChakraProvider>
  )
}

export default App
