import FormulaireMail from "./FormulaireMail"

export default function CreationMail() {
  const mail = {
    attachments: [],
    bcc: [],
    cc: [],
    from: "",
    html: "",
    text: "",
    messageId: "",
    oneClickUnsubscribe: true,
    references: [],
    rejected: [],
    subject: "",
    to: [],
  }
  return (
    <FormulaireMail mail={mail} />
  )
}