/**
 * 
 * @param {*} date 
 * @param {*} options
 * @param {boolean} options.full Should be full date time
 * @returns 
 */
export function emailDate(date, options) {
  date = new Date(date)
  if (options && options.full) {

    return Intl.DateTimeFormat('fr-FR', {
      month: "short",
      day: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit"
    }).format(date)
  } else if (new Date().toDateString() === date.toDateString()) {

    return Intl.DateTimeFormat('fr-FR', {
      hour: "2-digit",
      minute: "2-digit"
    }).format(date)
  } else {
    return Intl.DateTimeFormat('fr-FR', {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    }).format(date)
  }
}

export function pause(millisecondes) {
  return new Promise(
    resolve => setTimeout(resolve, millisecondes)
  );
}