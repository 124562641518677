import { Avatar, Flex, Text, useColorMode } from "@chakra-ui/react";
import { useState } from "react";
import { useLocation } from "react-router";
import { emailDate } from "../util/date-util";

export default function MailListItem({ mail, onClick, selectedId }) {
  onClick = onClick || (() => { })

  const { pathname } = useLocation()

  const { colorMode } = useColorMode()

  const [viewed, setViewed] = useState(mail.viewed)

  let estEnvoye = pathname === "/emails/messages-envoyes"

  let viewedWeight =  estEnvoye || viewed ? "normal" : "bold"

  let viewedColor =  estEnvoye || viewed ? null : colorMode === "light" ? "blue.600" : "blue.300"

  return (
    <Flex border={selectedId === mail._id ? "1px solid #63b3ed" : "none"} cursor="pointer" onClick={() => { onClick(); setViewed(true) }} direction="column" m={1} p={2} backgroundColor={colorMode === "light" ? "gray.200" : "whiteAlpha.100"} _hover={{ backgroundColor: colorMode === "light" ? "gray.300" : "whiteAlpha.300" }} borderRadius={5}>
      <Text color={viewedColor} fontSize="sm" fontWeight={viewedWeight} align="end">{emailDate(mail.date)}</Text>
      <Flex direction="row" alignItems="center">
        <Avatar size="sm" name={estEnvoye ? mail.to[0] : mail.from.split(/\s*</)[0]} />
        <Text color={viewedColor} ml={2} fontSize="lg" fontWeight="bold" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
          {estEnvoye ? mail.to.map(elt => elt.split(/\s*</)[0]).join(", ") : mail.from.split(/\s*</)[0]}
        </Text>
      </Flex>
      <Text w="100%" color={viewedColor} fontSize="md" fontWeight={viewedWeight} whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">{mail.subject}</Text>
      <Text w="100%" fontSize="sm" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">{mail.text}</Text>
    </Flex>
  )
}