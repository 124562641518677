import { Button } from "@chakra-ui/button"
import { useDisclosure } from "@chakra-ui/hooks"
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/modal"
import { forwardRef, useImperativeHandle } from "react"

export default forwardRef(({ titre, libelleAffirmatif, libelleNegatif, retour, children }, ref) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  useImperativeHandle(ref, () => ({
    ouvrir() {
      onOpen()
    }
  }))

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{titre}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {children}
        </ModalBody>
        <ModalFooter>
          <Button variant="outline" mr={2} onClick={() => { if (retour) retour(false); onClose() }}>{libelleNegatif || "NON"}</Button>
          <Button colorScheme="red" onClick={() => { if (retour) retour(true); onClose() }}>{libelleAffirmatif || "OUI"}</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
})