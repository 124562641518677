import { Flex, IconButton, Menu, MenuButton, MenuDivider, MenuGroup, MenuItem, MenuList, Progress, Tooltip, useColorMode } from "@chakra-ui/react"
import { Filter, SortDown } from "react-bootstrap-icons"
import { useScreenLessThan } from "../util/screen-size-util"
import { useBottomScrollListener } from "react-bottom-scroll-listener"

import MailListItem from "./MailListItem"

export default function MailList({ mails, onMailSelected, selectedId, onBottomReached, loading, display }) {
  const { colorMode } = useColorMode()

  const screenLessThanMd = useScreenLessThan('md')

  const ref = useBottomScrollListener(() => {
    if (!loading && onBottomReached) onBottomReached()
  })

  return (
    <Flex display={display} ref={ref} h="100%" width={screenLessThanMd ? "auto" : "320px"} resize="none" overflowY="auto" overflowX="hidden" direction="column" p={2} m={1} borderRadius={5} backgroundColor={colorMode === "light" ? "gray.100" : "whiteAlpha.50"}>
      <Flex justify="flex-end">
        <Menu>
          <Tooltip label="Filtrer et Trier">
            <MenuButton
              px={3}
              py={2}
              transition="all 0.2s"
              borderRadius="md"
              _hover={{ bg: colorMode === "light" ? "gray.200" : "whiteAlpha.300" }}
              _expanded={{ bg: colorMode === "light" ? "gray.200" : "whiteAlpha.300" }}>
              <Filter />
            </MenuButton>
          </Tooltip>
          <MenuList>
            <MenuGroup title="Filtrer par">
              <MenuItem>Non lus</MenuItem>
              <MenuItem>Marqués</MenuItem>
            </MenuGroup>
            <MenuDivider />
            <MenuGroup title="Trier par">
              <MenuItem>Date</MenuItem>
              <MenuItem>De</MenuItem>
              <MenuItem>À</MenuItem>
            </MenuGroup>
          </MenuList>
        </Menu>
        <Tooltip label="Ordre de tri">
          <IconButton icon={<SortDown />} backgroundColor="transparent" />
        </Tooltip>
      </Flex>
      {mails.map((mail, i) => (
        <MailListItem key={mail._id} selectedId={selectedId} mail={mail} onClick={() => { if (onMailSelected) { onMailSelected(i) } }} />
      ))}
      {loading &&
        <Flex p={2}>
          <Progress size="xs" isIndeterminate width="100%" colorScheme="blue" />
        </Flex> }
    </Flex>
  )
}