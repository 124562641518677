import { createContext, useContext, useReducer } from "react"

const StoreContext = createContext()
const initialState = {
  profil: {},
  nonLus: 0
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'profil':
      return {
        ...state,
        profil: action.profil
      }
    case 'nonLus':
      return {
        ...state,
        nonLus: action.nonLus
      }
    case 'decrementerNonLus':
      return {
        ...state,
        nonLus: state.nonLus - 1
      }
    case 'incrementerNonLus':
      return {
        ...state,
        nonLus: state.nonLus + 1
      }
    default:
      throw new Error(`Action inconnu ${action.type}`)
  }
}

export const StoreProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <StoreContext.Provider value={{ state, dispatch }}>
      {children}
    </StoreContext.Provider>
  )
}

export const useStore = () => useContext(StoreContext)